'use strict'

###*
 # @ngdoc object
 # @name mundoAuthentication
 # @description

###
angular
  .module 'mundoAuthentication', [
    'ui.router'
  ]
